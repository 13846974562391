<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      @resetBtnClick="handleResetClick"
      :pageInfo="pageInfo"
    >
      <template #customForm>
        <el-col :span="24" style="margin-bottom: 20px; padding: 15px">
          <div
            class="map"
            style="
              border: 1px solid #ccc;
              padding: 15px;
              height: 560px;
              border-radius: 5px;
            "
          >
            <div class="mapLeftStyle">
              <el-input
                v-model="input"
                placeholder="Please Input Location"
                class="controls"
                @input="changeInput"
                :suffix-icon="Search"
              >
                <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
              </el-input>
              <div class="card" v-if="list.length > 0">
                <div class="item" v-for="(item, index) in list" :key="index">
                  <div @click="confirm(item)">
                    <div class="title">
                      {{ item.structured_formatting.main_text }}
                    </div>
                    <div class="address">
                      {{ item.structured_formatting.secondary_text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mapStyle">
              <div class="mapRightStyle">
                <div :style="googleMapStyle" class="googleMap" id="mapID"></div>
              </div>
            </div>
          </div>
        </el-col>
      </template>
    </page-form>
  </div>
</template>
<script setup>
import { Loader } from '@googlemaps/js-api-loader'
import pageForm from '@/components/PageForm/page-form.vue'
import { baseNewFormConfig } from './config/new'
import { baseUpdateFormConfig } from './config/edit'
import { baseViewFormConfig } from './config/view'
import { Search } from '@element-plus/icons'

import {
  ref,
  getCurrentInstance,
  computed,
  defineProps,
  onMounted
  // nextTick
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'
import { ElMessage } from 'element-plus'
const routes = useRoute()
const router = useRouter()
const { t } = i18n.global
//  1、预定义当前页面的map映射数组
const props = defineProps({
  // mapType属性值为:
  // selectPoint时是地图选点
  // POLYGON是绘制地图多边形区域

  // 地图id
  mapID: {
    type: String,
    default: () => {
      return 'googleMap'
    }
  },
  // 谷歌地图类型
  mapType: {
    type: String,
    default: () => {
      return 'POLYGON'
    }
  },
  // 谷歌地图样式
  googleMapStyle: {
    type: Object,
    default: () => {
      return {
        wdith: '100%',
        height: '500px'
      }
    }
  },
  // 谷歌地图配置
  mapOptions: {
    type: Object,
    default: () => {
      return {
        // 为了关闭默认控件集,设置地图的disableDefaultUI的属性为true
        disableDefaultUI: false,
        //  启用缩放和平移
        gestureHandling: 'greedy',
        panControl: true,
        zoomControl: true,
        scaleControl: true,
        // 关闭街景
        streetViewControl: false
      }
    }
  },
  // 谷歌地图中心点
  googleMapCenter: {
    type: Object,
    default: () => {
      return {
        lat: 22.54856959771482,
        lng: 113.93745366401345
      }
    }
  },
  // 谷歌地图缩放级别
  zoom: {
    type: Number,
    default() {
      return 11
    }
  },
  // 谷歌地图图形path
  mapPath: {
    type: String,
    default: () => {
      return ''
    }
  }
})

// 地图选点回显值
// const longlat = computed(() => {
//   return props.googleMapCenter?.lng + ',' + props.googleMapCenter?.lat
// })

// 标记点
// const marker = ref([])
// 图形实例
const graphicalExample = ref(null)
// 图形路径经纬度
// const graphicalPath = ref([])
const apiKey = 'AIzaSyCWCn-ee3X-JDx1LywCL247DEgc_8Z8LjY'
let googleApi = ''
// 输入框模糊查询
const searchBox = ref(null)

const service = ref(null)

const geocoder = ref(null)

const marker = ref(null)

const initialLocations = ref('')
const googleMap = ref(null)
const input = ref('')
const list = ref([])
const changeInput = (e) => {
  console.log(e)
  searchBox.value.getPlacePredictions({ input: e }, (event, status) => {
    console.log(event, '===')
    if (status === 'OK') {
      list.value = event || []
      // place_id 后面有用，所以只保留存在place_id的数据
      list.value = list.value.filter((x) => x.place_id)
      console.log(event, '===', list.value)
    } else {
      list.value = []
    }
  })
}

// 点击一行地址
const confirm = (e) => {
  // 搜索地点和检索地点详细信息
  service.value.getDetails({ placeId: e.place_id }, (event, status) => {
    if (status === 'OK') {
      console.log(event.name, '===', event)
      input.value = event.name
      // if (!event.name) return;
      const str = event.name
      // 对请求进行地理编码
      geocoder.value.geocode({ address: str }, GetMapLocation)
    } else {
      console.error('parse error')
    }
  })
}

const GetMapLocation = (results, status) => {
  if (status === 'OK') {
    console.log(results[0].geometry.location, results[0], results, '查验')
    googleMap.value.setCenter(results[0].geometry.location)
    marker.value.setPosition(results[0].geometry.location)
  } else {
    console.log('error')
  }
}

const graphicalPath = ref('')
const getLocation = (vertices) => {
  graphicalPath.value = []
  if (Array.isArray(vertices) && vertices.length > 0) {
    vertices.forEach((vertex, index) => {
      const lat = vertex.lat() // 获取纬度
      const lng = vertex.lng() // 获取经度
      graphicalPath.value.push(lat + ',' + lng)
      // 这里可以执行其他逻辑，例如将坐标发送到服务器或更新UI
    })

    graphicalPath.value = graphicalPath.value.join(';')
    console.log(graphicalPath.value)
  }
}

// })

const pageMapArray = [
  {
    //  view routesName 跟你配置路由的name相关
    routesName: 'geoFenceView',
    baseFormConfig: baseViewFormConfig,
    pageTitle: t('general.router-geo-fence-view'),
    pageType: 'view'
  },
  {
    routesName: 'geoFenceEdit',
    baseFormConfig: baseUpdateFormConfig,
    pageTitle: t('general.router-geo-fence-edit'),
    pageType: 'edit',
    //  edit page特有的属性 reset时不将表单置为空
    isResetFields: false
  },
  {
    routesName: 'geoFenceNew',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('general.router-geo-fence-new'),
    pageType: 'add'
  }
]
//  2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

const store = useStore()
//  3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig

const baseFormConfigRef = computed(() => {
  return baseFormConfig
})

//  4、区分当前页面是否需要转化options选项
const configOptions = ref({})
currentPageConfig.baseFormConfig = baseFormConfigRef
configOptions.value = currentPageConfig

const id = routes.params.id

const { appContext } = getCurrentInstance()
const pageInfo = ref({})
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

//  5、定义方法获取当前页面的初始化数据
const getCurrentPageInfo = async () => {
  const data = await store.dispatch('geoFence/getGeoFenceById', {
    id
  })
  initialLocations.value = data?.areaPoint
  graphicalPath.value = data?.areaPoint
  console.log(initialLocations.value)
  data.tenantId = store.getters.tenantDataMap[data?.tenantId]
  pageInfo.value = data
}

//  6、判断当前页是否需要调用getCurrentPageInfo
if (['view', 'edit'].includes(pageType)) {
  getCurrentPageInfo()
} else {
  pageInfo.value = {}
}

const handleResetClick = () => {
  polygons.value.forEach((polygon) => {
    polygon.setMap(null)
  })
  polygons.value = []
  router.go(0)
}

const polygons = ref([])

onMounted(async () => {
  const data = await store.dispatch('geoFence/getGeoFenceById', {
    id
  })
  initialLocations.value = data?.areaPoint
  if (initialLocations.value) graphicalExample.value = true
  // nextTick(() => {
  const loader = new Loader({
    apiKey, // 之前的key
    version: 'weekly', // 版本
    libraries: ['places', 'drawing'], // 插件库places为基础库 drawing为绘制工具库
    region: 'HongKong',
    language: 'en'
  })
  let lat = ''
  let lng = ''
  if (initialLocations.value) {
    const items = initialLocations.value?.split(';')
    const firstPointLocation = items[0]?.split(',')
    lat = firstPointLocation[0]
    lng = firstPointLocation[1]
  }
  console.log(lat, lng)
  const mapOptions = {
    center: {
      lat: lat * 1 || props.googleMapCenter.lat * 1,
      lng: lng * 1 || props.googleMapCenter.lng * 1
    }, // 中心点
    zoom: props.zoom, // 缩放级别
    ...props.mapOptions, // 其他配置
    language: 'en'
  }
  loader
    .load()
    .then((google) => {
      const map = new google.maps.Map(
        document.getElementById('mapID'),
        mapOptions
      )
      googleMap.value = map
      searchBox.value = new google.maps.places.AutocompleteService()
      service.value = new google.maps.places.PlacesService(map)
      geocoder.value = new google.maps.Geocoder()
      marker.value = new google.maps.Marker({
        map: map,
        position: {},
        draggable: true
      })
      googleApi = google
      if (props.mapType === 'POLYGON') {
        // 如果是绘制图形类型则开始实例化绘制工具库
        const drawingModesList = {
          MARKER: googleApi.maps.drawing.OverlayType.MARKER, // 标记点
          CIRCLE: googleApi.maps.drawing.OverlayType.CIRCLE, // 圆形
          POLYGON: googleApi.maps.drawing.OverlayType.POLYGON, // 多边形
          POLYLINE: googleApi.maps.drawing.OverlayType.POLYLINE, // 折线
          RECTANGLE: googleApi.maps.drawing.OverlayType.RECTANGLE // 矩形
        }
        console.log(drawingModesList)

        const defaultOptions = {
          drawingControl: true,
          drawingControlOptions: {
            position: googleApi.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              drawingModesList.MARKER,
              // drawingModesList.CIRCLE,
              drawingModesList.POLYGON
            ]
          }
        }
        const disabledOptions = {
          drawingControl: false,
          drawingControlOptions: {
            drawingModes: []
          }
        }
        const options = pageType === 'view' ? disabledOptions : defaultOptions
        const drawingManager = new googleApi.maps.drawing.DrawingManager({
          ...options,
          polygonOptions: {
            fillColor: 'blue',
            strokeColor: 'blue',
            strokeOpacity: 0.8,
            fillOpacity: 0.4,
            strokeWeight: 5,
            clickable: false,
            editable: true,
            zIndex: 1
          }
        })
        drawingManager.setMap(map)
        googleApi.maps.event.addListener(
          drawingManager,
          'polygoncomplete',
          (e) => {
            // 存在就清除上一个图形
            polygons.value.forEach((polygon) => {
              polygon.setMap(null)
            })
            polygons.value = []

            const polygonPath = e.getPath() // 获取多边形的路径
            const vertices = polygonPath.getArray() // 获取路径中的所有顶点
            getLocation(vertices)

            // 创建一个新的多边形并添加到数组
            const newPolygon = new googleApi.maps.Polygon({
              paths: vertices,
              fillColor: 'blue',
              strokeColor: 'blue',
              strokeOpacity: 0.8,
              fillOpacity: 0.4,
              strokeWeight: 5,
              clickable: true,
              editable: true,
              zIndex: 1
            })
            newPolygon.setMap(googleMap.value)
            polygons.value.push(newPolygon)
          }
        )

        // 回显多边形
        if (initialLocations.value) {
          // 解析initalLocations

          const arr = initialLocations.value?.split(';')?.map((item) => {
            return {
              lat: item?.split(',')[0] * 1,
              lng: item?.split(',')[1] * 1
            }
          })

          // Construct a draggable red triangle with geodesic set to true.
          const graphicalExamplePolygon = new googleApi.maps.Polygon({
            paths: arr,
            fillColor: 'blue',
            strokeColor: 'blue',
            strokeOpacity: 0.8,
            fillOpacity: 0.4,
            strokeWeight: 5,
            clickable: true,
            editable: true,
            zIndex: 1
          })
          graphicalExamplePolygon.setMap(googleMap.value)
          polygons.value.push(graphicalExamplePolygon)
          // 监听回显图形每一个路径点
          googleApi.maps.event.addListener(
            graphicalExample.value.getPath(),
            'insert_at',
            (event) => {
              console.log(event)
              // this.setPath(this.graphicalExample.getPath().Ld)
            }
          )
          googleApi.maps.event.addListener(
            graphicalExample.value.getPath(),
            'set_at',
            (event) => {
              console.log(event)
              // this.setPath(this.graphicalExample.getPath().Ld)
            }
          )
        }
      }
    })
    .catch((e) => {
      // do something
      console.log(e)
    })
})

//  7、定义被提交的方法
const handleSubmit = async (data) => {
  if (!graphicalPath.value) {
    ElMessage.error('Please draw a polygon')
    return
  } else {
    data.areaPoint = graphicalPath.value
  }

  if (pageType === 'add') {
    const form = { id: '', ...data }
    handleMessageCommit('geoFence/handleNewGeoFence', form).then(() => {
      router.push('/geo-fence/overview')
    })
  } else if (pageType === 'edit') {
    const form = { id: id, ...data }
    handleMessageCommit('geoFence/handleUpdateGeoFence', form).then(() => {
      router.push('/geo-fence/overview')
    })
  }
}
</script>

<style scoped>
::v-deep .mapLeftStyle .el-input__inner {
  position: relative; /* 为伪元素提供定位上下文 */
  border-width: 1px;
  margin-top: 10px;
  position: relative;
  border: none;
  border-radius: 0 !important;
  border-bottom: 1px solid #999999;
  border-bottom-width: 1px;
  background-color: #ffffff; /* 白色背景 */
  color: #333333; /* 深灰色字体 */
  font-size: 16px; /* 中等字体大小 */
  padding: 10px; /* 内边距 */
  transition: background-color 0.3s, border-color 0.3s; /* 过渡效果 */
}
::v-deep .mapLeftStyle .el-input__inner::before {
  content: '\\f002'; /* Font Awesome 的搜索图标编码 */
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #292929; /* 图标颜色 */
  font-size: 18px; /* 图标大小 */
}
::v-deep .mapLeftStyle .el-input--prefix .el-input__inner {
  padding-left: 40px; /* 为前缀图标留出空间 */
}

::v-deep .mapLeftStyle .el-input__icon {
  position: absolute;
  top: 10px; /* 与输入框顶部对齐 */
  left: 10px; /* 与输入框左侧对齐 */
  font-size: 24px; /* 图标大小 */
  color: #292929; /* 图标颜色 */
}

::v-deep .mapLeftStyle .popper__arrow {
  top: 0px !important; /* 箭头样式 */
  background-color: #ffffff; /* 箭头背景颜色与输入框背景一致 */
  border-color: #999999; /* 箭头边框颜色与输入框边框颜色一致 */
  border-radius: 4px; /* 箭头圆角 */
}

::v-deep .mapLeftStyle .el-input__prefix {
  color: #292929; /* 前缀图标颜色 */
  font-size: 24px; /* 前缀图标大小 */
}
.map {
  display: flex;
  .mapLeftStyle {
    width: 300px; /* 保持宽度不变 */
    min-width: 300px; /* 保持最小宽度不变 */
    min-height: 520px; /* 保持最小高度不变 */
    background: #ffffff;
    overflow-y: auto; /* 仅在垂直方向上显示滚动条 */
    overflow-x: hidden; /* 隐藏水平方向的滚动条 */

    .controls {
      padding: 0 15px; /* 减小内边距 */
      height: 40px; /* 减小高度 */
    }
    .card {
      padding: 0 15px; /* 减小内边距 */
      .item {
        cursor: pointer;
        padding: 10px 15px; /* 增加左右内边距 */
        border-bottom: 2px solid #eaeaea; /* 添加一个简单的下划线 */
        transition: background-color 0.3s, box-shadow 0.3s; /* 平滑的过渡效果 */
        margin-bottom: 5px; /* 增加底部外边距，避免元素堆叠 */
        position: relative; /* 为可能的子元素定位提供基础 */
      }

      .item:hover {
        background-color: #f7f7f7; /* 鼠标悬停时改变背景颜色 */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加轻微的阴影效果 */
      }

      .item .title {
        font-size: 16px; /* 增加标题字体大小 */
        font-family: Arial, sans-serif; /* 使用无衬线字体 */
        font-weight: bold;
        color: #333; /* 深灰色字体颜色 */
        line-height: 24px; /* 增加行高 */
        margin: 0; /* 移除默认的上下外边距 */
        display: block; /* 将标题作为块级元素处理 */
      }

      .item .title::after {
        content: ''; /* 生成内容，但不显示任何文本 */
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #007bff; /* 蓝色下划线 */
        transition: width 0.3s; /* 平滑的过渡效果 */
      }

      .item:hover .title::after {
        width: 50%; /* 鼠标悬停时下划线变宽 */
      }

      .item .address {
        font-size: 14px; /* 减小地址字体大小 */
        font-family: Arial, sans-serif; /* 使用无衬线字体 */
        color: #666; /* 淡灰色字体颜色 */
        line-height: 20px; /* 减少行高 */
        margin-top: 5px; /* 在地址上方添加一些空间 */
      }
    }
  }
  .mapStyle {
    width: 100%;
    .mapLeftStyle {
      margin-right: 5px;
      display: inline-block;
      .inputDUStyle {
        display: inline-block;
        width: 47%;
      }
      .inputDUStyle:first-child {
        margin-right: 1rem;
      }
      .inputDUStyle {
        margin-bottom: 1rem;
      }
    }
    .mapRightStyle {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      .map {
        width: 100%;
        min-height: 100vh;
      }
    }
  }
}
</style>
