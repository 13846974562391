export const baseNewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'general.name',
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      }
    }
  ],
  validateRules: {
    name: [{ required: true, message: 'general.required', trigger: 'blur' }]
  }
}
